import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Wood from '../images/wood.jpg';
import Speisekarte from '../images/speisekarte_helmarts.png';
import SpeisekartePdf from '../images/speisekarte_helmarts.pdf';


const MenuPage = () => (
  <Layout>
    <SEO title="Speisekarte • Burger & Drinks" />

    <div className="site-blocks-cover inner-page overlay menu-cover" style={{ backgroundImage: `url(${Wood})` }}>
      <div className="row justify-content-center">
        <div className="col-12 mt-3">
          <h1 className="text-uppercase text-center"><span>Helmart's Speisekarte</span></h1>
        </div>
      </div>
    </div>

    <div className="yellow-lines"></div>

    <div className="absolute-center">
      <a href={SpeisekartePdf} target="_blank"><img src={Speisekarte} className="menu" alt="Helmart's Speisekarte" /></a>
    </div>

    <div className="menu-space"></div>

  </Layout>
)

export default MenuPage

// pdfjam --papersize '{29.7cm,42cm}' --landscape --nup 2x1 menu1.pdf menu2.pdf --outfile speisekarte_helmarts.pdf
// pdfjam --papersize '{21cm,29.7cm}' --landscape --nup 2x1 menu2_3.pdf menu2_1.pdf --outfile speisekarte_helmarts.pdf
// convert -density 300 -resize 1200 -quality 50 -background white -alpha remove speisekarte_helmarts.pdf speisekarte_helmarts.png
